div.Container{
    /* border: 4px solid blue; */
    width: 100%;
    height: 100%;
    padding-right: 2%;
    padding-left: 2%;
    background-color: #000;

    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

    .MenuBtns{
        /* border: 1px solid red; */
        height: 90%;
        overflow: hidden;
        text-align: center;
                
        .menubtn {
            line-height: 250%;
            display: inline;
            padding: 10px;
            background-color: transparent;
            border: 0px;
            color: var(--mainWhite);
            font-size: 2.2vh;
            font-weight: 200;
            cursor: pointer;
        
            &.menubtnActive {
                font-weight: 400;
                text-decoration: underline var(--mainWhite);
            }
        }
    }

    div.BigappsLogo{
        /* border: 1px solid red; */
        height: 71%;
        width: max-content;
        cursor: pointer;

        *:hover{
            opacity: .9;
        }
    }

    div.MobileBtns{
        /* border: 2px solid red; */
        /* width: max-content; */
        display: flex;
        align-items: center;
        
        img {
            max-height: 65px;
        }

        *{
            cursor: pointer;
            padding: 10px;
            width: auto;
        }

        *:hover{
            opacity: .8;
        }
    }

    div.VersionDiv {
        color: var(--mainWhite);
        font-size: 10px;
        position: fixed;
        left: 1px;
    }
}