/* @media (-webkit-min-device-pixel-ratio: 1) { */
div.Container{
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    /* border: 4px solid #F6F2F2; */
    margin-top: 10px;
    border-radius: 15px;
    overflow: auto;
    /* box-shadow: 0 .1vh .4vw rgba(0,0,0,.1); */

    div.TopContent{
        /* border: 1px solid red; */
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;

        div.CloseButton {
            button{
                font-size: 25px;
                font-weight: 600;
                background-color: unset;
                cursor: pointer;
                border: none;
                outline: none;
            }
        }

        div.ItemName{
            cursor: default;
            padding-right: 25px; 
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis; 

            flex: 1;
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            color: var(--mainMainOrBlack);
        }
    }

    /* div.BodyContent{ */
        form{
            /* border: 4px solid red; */
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 90%;
            
            div.FormButtons{
                /* border: 4px solid blue; */
                margin-top: 20px;
                height: 22%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                div.PrevAndSubmitBtns{
                    display: flex;
                    flex-direction: column;
                    /* gap: 5px; */
                }

                /* div.PrevAndSubmitBtns > * + *{
                    margin-top: 5px;
                } */
                
                button{
                    /* color: #DC143C; */
                    border-radius: 25px;
                    width: 280px;
                    max-width: 100%;
                    font-size: 25px;
                    font-weight: 500;
                    height: 40px;
                    font-family: 'Heebo', sans-serif;
                    background-color: unset;
                    /* border: 1px solid #DC143C; */
                    cursor: pointer;
                    outline: none;
                    /* &:hover {
                        background-color: unset !important;
                    } */
                    &:disabled {
                        background-color: gray;
                        pointer-events: none;
                        /* &:hover{ opacity: 0.4; } */
                    }
                }
            }
        }
    /* } */
}

.prevButton {
    color: var(--mainMainOrBlack);
    font-size: 15px;
    border: none;
    height: min-content;
    width: 'max-content';
    padding: 0;
    margin: 0;
}

.nextButton {
    color: var(--mainMainOrBlack);
    border: 2px solid var(--mainMainOrBlack);
}

@media (max-resolution: 110dpi){
    div.Container{
        div.TopContent{
            div.ItemName{
                font-size:25px;
            }
        }
    }
}

@media (min-resolution: 130dpi){
    div.Container{
        div.TopContent{
            div.ItemName{
                font-size:15px;
            }
        }
    }
}

@media (min-resolution: 150dpi){
    div.Container{
        div.TopContent{
            div.ItemName{
                font-size:13px;
            }
        }
    }
}

@media (max-width: 850px){
    div.Container{
        div.TopContent{
            div.ItemName{
                font-size:25px;
            }
        }
        form{
            div.FormButtons{
                height: auto;
                div.PrevAndSubmitBtns{
                    button{
                        font-size: 20px;
                    }
                }
            }
        }
    }
}