.container{
  cursor: pointer;
  align-self: center;
  width: 100%;
  /* height: 150px; */
  height: max-content;
  /* margin: 0 0 10px 10px;
  padding: 10px 0 10px 0; */
  border-radius: var(--brdRadius);
  background-color: var(--mainWhite);
}
.TopDiv {
    text-align: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-content: stretch;
    margin-bottom: 10px;

    min-height: 90px;
}
.info {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  flex: 5;
  margin-right: 5px;
}
.infoRow {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  /* justify-content: space-between; */
}
.col {
  /* border: 2px solid red; */
  min-width: 170px;
  button {
    border-radius: var(--brdRadius);
    border: none;
    color: var(--mainWhite);
    font-size: 20px;
    background-color: var(--mainColor);
    cursor: pointer;
  }

}
.col_del {
  /* border: 2px solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 170px;
}

.timerWrapper{
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 5px;
  color: var(--mainColor);

  position: relative;

  .inner{
    position: absolute;
    /* border: 2px solid red; */

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    box-size: 60px;
    border-radius: 100%;

    .time{
      font-size: 20px; 
      font-weight: 600;
      line-height: 20px;
    }
    
    .unit{
      font-size: 8px;
      font-weight: 600;
    }
  }
}


.col_del > * + * {
  margin-right: 10%;
}

.colDesc {
  /* border: 2px solid green; */
  font-size: 1.2em;
  font-weight: 600;


}
.colVal {
  font-size: 1.2em;
  color: var(--mainMainOrBlack);
}
.image {
  flex: 1;
  align-self: center;
}
.line {
  width: 100%;
}
/* @media screen and (max-width: 600px) {
    .TopDiv {
      display: flex;
      flex-flow: column nowrap;
    }
  } */
@media (max-width: 600px) {
  div.container{
    
    .TopDiv {
      /* display: flex;
      flex-flow: column nowrap; */

      div.info{
        /* border: 3px solid blue; */
        div.infoRow{
          /* border: 1px solid red; */
          display: flex;
          justify-content: space-between;
          flex-flow: unset;

          div.col{
            /* border: 2px solid green; */
            min-width: unset;
            margin: auto;
            
            div.colDesc{
              font-size: .9em;
              font-weight: 600;
            }
            div.colVal{
              font-size: .8em;
            }
          }

          div.col_del{
            /* border: 3px solid pink; */
            margin: 0;
            width: 0;
          }
        }
      }
    }
  }
}
