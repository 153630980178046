div.Container{
    display: flex;
    flex-direction: column;
    background-color: rgb(223,223,223,0.97);
    /* position: relative; */
    /* top: 0; */
    /* bottom: -200%; */
    /* right: 50%;
    transform: translateX(50%); */
    z-index: 10000;
    padding: 40px;
    border-radius: 10px;
    width: 300px;
    margin: 0 auto;

    div.CouponTitle{
        margin-bottom: 10px;
        font-size: 14px;
    }

    div.CouponInput{
        /* display: block; */
        input{
            text-align: center;
            background-color: unset;
            border: none;
            border-bottom: 1px solid #a5a5a5;
            /* padding: 5px 10px; */
            font-size: 20px;
            outline: none;
        }
    }

    div.CouponError {
        span{
            color: #FF0000;
            font-size: 11px;
            margin-top: -5px;
        }
    }

    /* div.ErrorMsg{
        position: absolute;
        top: 46%;
        left: 50%;
        font-size: 11px;
        color: #FF0000;
    } */

    div.CouponBtns{
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* gap: 1vw; */
        margin-top: 20px;
        button:nth-child(1){
            color: gray;
            border-radius: 30px;
            width: 100px;
            height: 30px;
            background-color: unset;
            border: 1px solid gray;
            outline: none;
        }
        button:nth-child(2){
            color: var(--mainMainOrBlack);
            border-radius: 30px;
            width: 100px;
            height: 30px;
            /* border: 1px solid gray; */
            /* border: unset; */
            outline: none;
        }

        button.CouponOff {
            background-color: rgb(255, 255, 255, 0.07);
            border: 1px solid #a5a5a5;
        }

        button.CouponOn {
            background-color: var(--mainColor);
            border: unset;
        }

        button:hover{
            cursor: pointer;
        }
    }
    
    div.TimeBar {
        position: relative;

        span{
            position: absolute;
            left:50px;
            font-size: 10px;
            color: rgb(0, 0, 0, 0.4);
            font-weight: 600;
        }
    }
}
