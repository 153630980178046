div.ModalContainer{
    form{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        /* gap: 30px; */
    
        div.ModalTitle{
            text-align: center;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 30px;
    
            div.WrongCodeText{
                color: #FF0000;
                font-size: 15px;
                font-weight: 400;
            }
        }
    
        div.ModalInputs{
            margin-bottom: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            /* gap: 0.6vw; */
    

            input{
                margin: 7px;
            }
    
            div.NewCodeText{
                color: #FF0000;
                font-size: 17px;
    
                span:nth-child(1){
                    font-weight: 300;
                }
                span:nth-child(2){
                    font-weight: 600;
                    text-decoration: underline;
                }
    
                span:nth-child(1):hover{
                    cursor: context-menu;
                }
                span:nth-child(2):hover{
                    cursor: pointer;
                    color: #ff5c5c;
                }
            }
        }
    
        div.ModalBtns{
            display: flex;
            /* justify-content: space-around; */
            align-items: center;
            margin-top: 20px;
            /* gap: 7vw; */
            

            button{
                margin: 0 15px 0 15px;
                font-family: Heebo;
                outline: gray;
                border: none;
                border-radius: 25px;
                width: 120px;
                height: 50px;
                font-size: 20px;
                /* color: #ff0000; */
                background-color: #FFFF;
                cursor: pointer;
                
            }
    
            button:nth-child(2){
                color: var(--mainMainOrBlack);
                border: 1px solid var(--mainMainOrBlack);
            }
    
            button:nth-child(1){
                border: 1px solid #cacaca;
                color: #ababab;
            }
    
            /* button:nth-child(2):hover{
                background-color: #ff2323;
                color: #FFFF;
                cursor: pointer;
            }
    
            button:nth-child(1):hover{
                background-color: #cacaca;
                color: #ffff;
                cursor: pointer;
            } */
        }
    }
}