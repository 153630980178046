.SocialIcons{
    margin-top: 8px;
    display: flex;
    justify-content: center;
}

.MobileBtns {
    margin-top: 20px !important;
    width: 75%;
    margin: auto;
}

.burgerIcon {
    font-size: 38px;
    color: var(--mainWhiteOrBlack);
    border-radius: 20px;
    border: 4px solid var(--mainWhiteOrBlack);
}

div.StoresImg{
    margin-right: 10px;
    margin-left: 10px;
    border: 2px solid var(--mainWhite);
    border-radius: 25px;
    height: 42px;
    width: 42px; 
    cursor: pointer;

    img{
      width: 50px;
      height: 50px;
    }
    img:hover{
      cursor: pointer;
    }
    .StoresImgIcon {
      cursor: pointer;
      font-size: 25px;
      color: var(--mainWhite);
      margin: 7px;
    }
  }

  .LogIconDiv {
    border: 2px solid var(--mainWhite);
    border-radius: 25px;
    height: 42px;
    width: 42px; 

    .LogIconDivIcon {
      font-size: 25px;
      color: var(--mainWhite);
      margin: 7px;
    }
  }

div.Container{
    align-self: center;

    div.SidebarContent {
        min-height: 100%;
        position: absolute;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    div.AppTopMenu {
        text-align: center;
        display: flex;
        flex-flow: row-reverse wrap;
        justify-content: space-around;
        margin: 5px;

        display: flex;
        justify-content: center;
        align-items: center;

        /* div.SearchContainer{
            border: 2px solid blue;
            margin-right: 2vw;
        } */
        
    }
    
    div.menubtn {
        padding: 10px;
        background-color: transparent;
        border: 0px;
        color: var(--mainWhiteOrBlack);
        font-size: 24px;
        font-weight: 200;
        cursor: pointer;
    
        &.menubtnActive {
            font-weight: 400;
            text-decoration: underline var(--mainWhiteOrBlack);
        }

        a {
            color: inherit;
            text-decoration: inherit;
        }
    }
    input.searchbox {
        background: no-repeat right;
        background-size: 20px;
        width: 200px;
        cursor: pointer;
        border: 1px solid var(--mainWhite);
        border-radius: 31px;
        color: black;
    }

    div.BigappsLogo{
        /* border: 1px solid red; */
        width: 50%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        /* margin: auto; */
        /* margin-top: auto; */
        cursor: pointer;
        /* margin-top: 10%; */
        opacity: .7;
    }

    div.mobileMenubtn {
        border-bottom: 2px solid #808080;
        padding: 10px 0 10px 0;
        font-weight: 500;
        font-size: 20px;
        color: #FFF;

        a {
            color: inherit;
            text-decoration: inherit;
        }
    }

    div.MobVersionDiv {
        color: var(--mainColor);
        font-size: 1rem;
        margin-bottom: 15px;
    }
}