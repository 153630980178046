div.LoaderContainer{
  justify-self: right;
  flex-flow: column;
  flex: 1;
  margin: 0;
  border-radius: var(--brdRadius);
  background-color: var(--mainWhite);
  padding: 15px;
  min-height: 140px;
}

/* @media screen and (min-width: 601px) { */
  div.Container{
    justify-self: right;
    flex-flow: column nowrap;
    /* max-width: 60vw; */
    max-height: 100%;
    margin-bottom: 50px;
    flex: 4 4;
    overflow-x: auto;
    /* white-space: nowrap; */
    margin: 5px 5px 0 5px;
    border-radius: var(--brdRadius);

    div.TopDiv {
      text-align: center;
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      padding-bottom: 200px;

      div.Oops{
        /* border: 1px solid red; */
        margin: auto;
        margin-top: 20vh;
        color: var(--mainWhiteOrBlack);

        div.title{
          text-align: center;
          font-size: 3.5vw;
        }
        div.subtitle{
          text-align: center;
          font-size: 1.7vw;
        }
      }
    }

    
  }

  

  @media (max-width: 600px) {
    div.Container {
      max-height: 68vh;
    }
  }

  @media (max-width: 851px) {
    div.Container {
      div.couponMobile {
        position: absolute;
        left: 0px;
        bottom: 200px;
        color: var(--mainWhiteOrBlack);
        font-size: 16px;
        padding-left: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 25px;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        cursor: pointer;
        border: 2px solid var(--mainWhiteOrBlack);
        border-left: none;
        background-color: var(--mainColor);
      }
    }
  }

  @media (min-width: 850px) {
    div.Container {
      div.couponMobile {
        display: none;
      }
    }
  }