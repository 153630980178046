.container{
    direction: ltr;
    margin-bottom: 5vh;

    .closeBtn{
        margin: .7vh 0 0 .6vw;
        font-size: 1.3vw;
        cursor: pointer;
        width: min-content;
    }

    .body{
        /* border: 1px solid red; */
        margin: auto;
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .itemImage{
            width: 20%;
        }

        .itemName{
            margin-top: 1vh;
            font-size: 1.3vw;
            font-weight: 600;
        }

        .details{
            /* border: 1px solid red; */
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 100%;
            
            .pair{
                /* border: 1px solid red; */
                width: 100%;
                .detail{
                    /* border: 1px solid red; */
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
    
                    margin: 1vw;
    
                    .detailTitle{
                        font-size: 1vw;
                        font-weight: 600;
                    }
                    .detailContent{
                        font-size: 1vw;
                    }
                }
            }
        }

        .comments{
            /* border: 1px solid red; */
            width: 100%;
            margin-top: 5vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .titleComments{
                font-size: 1vw;
                font-weight: 600;
            }

            .bodyComments{
                font-size: 1vw;
                width: 60%;
                word-wrap: break-word;
                text-align: center;
            }
        }
    }
}

@media (max-width: 700px){
    .container{
        .closeBtn{
            font-size: 3vw;
            margin-left: 1.2vw;
        }

        .body{
            width: 90%;
            .itemName{
                font-size: 3vw;
                color: var(--mainColor);
            }
            .details{
                .pair{
                    .detail{
                        .detailTitle{
                            font-size: 1.8vw;
                        }
                        .detailContent{
                            font-size: 1.7vw;
                        }
                    }
                }
            }

            .comments{
                .titleComments{
                    font-size: 2vw;
                }
                .bodyComments{
                    font-size: 1.9vw;
                }
            }
        }

    }
}